import React, { useState, Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import Switch from "react-router-dom/Switch";

// import App from "./home/App";
// import Region from "./bundesland/Region";
// import News from "./news/News";
// import Charts from "./charts/Charts";
// import Social from "./social/Social";
// import Pushes from "./pushes/Pushes";
// import TopCommented from "./topcommented/TopCommented";

import "./index.css";

import { Fab, ThemeProvider, createTheme } from "@material-ui/core";

import MenuOpenIcon from "@material-ui/icons/MenuOpen";

import Menu from "./Menu";
import Spinner from "react-svg-spinner";

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
const App = lazy(() => import("./home/App"));
const Region = lazy(() => import("./bundesland/Region"));
const News = lazy(() => import("./news/News"));
const Charts = lazy(() => import("./charts/Charts"));
const Social = lazy(() => import("./social/Social"));
const Pushes = lazy(() => import("./pushes/Pushes"));
const Topics = lazy(() => import("./topics/Topics"));
const TopCommented = lazy(() => import("./topcommented/TopCommented"));

window.redscreen = {};

const theme = createTheme({
  palette: {
    type: "dark",
    primary: { main: "#e2001f" },
    background: {
      default: "#222222",
      paper: "#222222",
    },
  },
});

function Root() {
  const [open, setOpen] = useState(false);

  var label = new URLSearchParams(useLocation().search).get("label");
  if (!label) label = useLocation().pathname.substring(1);

  const toggleOpen = () => {
    setOpen((state) => !state);
  };

  return (
    <div>
      <Menu open={open} toggleOpen={toggleOpen} />

      <Fab
        style={{
          position: "fixed",
          zIndex: 2000,
          bottom: 15,
          left: 20,
          width: 56,
        }}
        onClick={toggleOpen}
      >
        <MenuOpenIcon />
      </Fab>

      <Suspense
        fallback={
          <div
            style={{
              height: "100%",
              flexDirection: "column",
              marginTop: 100,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spinner size="64px" color="#E00B26" />
          </div>
        }
      >
        <Switch>
          <Route exact path="/" component={App} />
          <Route path="/videos" render={() => <App endpoint="rt-videos" />} />
          <Route path="/bundesland" component={Region} />
          <Route path="/news" component={News} />
          <Route path="/charts" component={Charts} />
          <Route path="/social" component={Social} />
          <Route path="/pushes" component={Pushes} />
          <Route path="/topics" component={Topics} />
          <Route path="/topcommented" component={TopCommented} />
        </Switch>
      </Suspense>

      {label && (
        <div class="ribbon">
          <div class="ribbon-inner">
            <span>{label}</span>
          </div>
        </div>
      )}
    </div>
  );
}
// {(new URLSearchParams(location.search).aaa)}
ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Root />
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);
